import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Dashboard from './assets/js/Dashboard.js';
import * as serviceWorker from './serviceWorker';

//import './assets/fonts/Poppins-Medium.ttf';
//import './assets/fonts/Poppins-Light.ttf';
//import './assets/fonts/Poppins-Bold.ttf';
//import './assets/fonts/Poppins-Regular.ttf';
import './assets/fonts/Poppins-SemiBold.ttf';

import './assets/fonts/Roboto-Regular.ttf';
import './assets/fonts/Roboto-Medium.ttf';
import './assets/fonts/Roboto-Bold.ttf';
import './assets/fonts/Roboto-Bold.ttf';

ReactDOM.render(<Dashboard />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


