import React from 'react';
import './TimeSeriesStatus.css';

import TimeSeriesStatusValue from './TimeSeriesStatusValue.js';

function TimeSeriesStatus(props) {
  return (
    <div className="TimeSeriesStatus">
        <TimeSeriesStatusValue {...props}/>
    </div>
  );
}

export default TimeSeriesStatus;


