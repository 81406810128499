import React from 'react';
import './Dashboard.css';

import Header from './Header.js';
import Cards from './Cards.js';

function Dashboard() {
  return (
    <div className="Dashboard">
<Header />
<Cards />
    </div>
  );
}

export default Dashboard;
