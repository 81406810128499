import React from 'react';
import './AictivityTextW.css';



function AictivityTextW() {
  return (
    <div className="AictivityTextW">
<div className="AictivityTextW-ActivityHeader" > </div>
<div className="AictivityTextW-ActivitiyItem1" > </div>
<div className="AictivityTextW-ActivitiyItem2" > </div>
<div className="AictivityTextW-ActivitiyItem3" > </div>
<div className="AictivityTextW-ActivitiyItem4" > </div>
    </div>
  );
}

export default AictivityTextW;
