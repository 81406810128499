import React from 'react';
import './TimeSeries.css';

import TimeSeriesTimes from './TimeSeriesTimes.js';
import TimeSeriesStatus from './TimeSeriesStatus.js';

class TimeSeries extends React.Component {

    constructor(props) {
        super(props);
        var objId = Math.random();
        this.state = {
            objId: objId,
            props: props,
            baseTag: props.baseTag,
            heading: props.heading,
            subheading: props.subheading,
        };
//        console.log('TimeSeries, baseTag:', props.baseTag, ' objId:', objId);
    };

    componentDidMount() {
//        this.getTemperatureData();
//        this.temperatureInterval = setInterval(this.getTemperatureData, 60*1000);
    };

    componentWillUnmount() {
//        if (this.temperatureInterval) {
//            clearInterval(this.temperatureInterval);
//        }
    }

    render(){

        const tag = `${this.state.baseTag}-canvas`;
//        console.log(tag);

        return (
            <div className="TimeSeries">
            <div className="TimeSeries-Heading" >{this.state.heading}</div>
                <TimeSeriesTimes {...this.state.props}/>
            <div className="TimeSeries-Chart">
                <canvas className="TimeSeries-Chart-Canvas" id={tag}></canvas>
            </div>
                <TimeSeriesStatus {...this.state.props}/>
            </div>
        );
    }

};

//<div className="TimeSeries-SubHeading" >{this.state.subheading}</div>

export default TimeSeries;

//<img src={TempChart} className="Temperature-TempChart" alt="" />
