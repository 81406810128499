import React from 'react';
import './Weather.css';

import TimeAndDate from './TimeAndDate.js';
// import PinAndAddress from './PinAndAddress.js';

function Weather() {
  return (
    <div className="Weather">
<TimeAndDate />
    </div>
  );
}
// <PinAndAddress />

export default Weather;
