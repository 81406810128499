import React from 'react';
import './FirstColumn.css';

import Weather from './Weather.js';
import WindowStatus from './WindowStatus.js';
import Motion from './Motion.js';

function FirstColumn() {
  return (
          <div className="FirstColumn">
          <Weather />
          <div className="FirstColumn-WindowStatusAndMotion">
          <WindowStatus />
          <Motion baseTag="motion" />
          </div>
          </div>
  );
}

export default FirstColumn;


