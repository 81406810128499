import React from 'react';
import './SecondColumn.css';

import TimeSeries from './TimeSeries.js';
import locales from '../locales/locale.json';

function SecondColumn() {

    const props_temperatrure = {
        baseTag: "temperature",
        heading: locales.temperature,
        subheading: "...",
    };

    const props_co2 = {
        baseTag: "co2",
        heading: locales.co2,
        subheading: "...",
    };

    const props_humidity = {
        baseTag: "humidity",
        heading: locales.humidity,
        subheading: "...",
    };

    return (
            <div className="SecondColumn">
            <TimeSeries {...props_temperatrure} />
            <TimeSeries {...props_co2} />
            <TimeSeries {...props_humidity} />
            </div>
    );
}

export default SecondColumn;
