import React from 'react';
import './TimeSeriesStatusValue.css';



function TimeSeriesStatusValue(props) {
    
    const tag = `${props.baseTag}-status`;
    
  return (
    <div className="TimeSeriesStatusValue">
          <div className="TimeSeriesStatusValue-Text" id={tag}>12ºC</div>
    </div>
  );
}

export default TimeSeriesStatusValue;


