import React from 'react';
import './TimeSeriesTimes.css';



function TimeSeriesTimes(props) {
    
    const tag_1 = `${props.baseTag}-time1`;
    const tag_2 = `${props.baseTag}-time2`;
    const tag_3 = `${props.baseTag}-time3`;
    const tag_4 = `${props.baseTag}-time4`;
    const tag_5 = `${props.baseTag}-time5`;
//    console.log(tag_1, tag_2, tag_3, tag_4, tag_5);
    
    return (
            <div className="TimeSeriesTimes">
            <div className="TimeSeriesTimes-Time1" id={tag_1}>14:15</div>
            <div className="TimeSeriesTimes-Time2" id={tag_2}>15:15</div>
            <div className="TimeSeriesTimes-Time3" id={tag_3}>16:15</div>
            <div className="TimeSeriesTimes-Time4" id={tag_4}>17:15</div>
            <div className="TimeSeriesTimes-Time5" id={tag_5}>18:15</div>
            </div>
    );
}

export default TimeSeriesTimes;


