import React from 'react';
import './WindowAndActivity.css';

//import WindowOpen from '../images/window open slice.png';
//import WindowClosed from '../images/window closed slice.png';
import AictivityTextW from './AictivityTextW.js';

//async function fetchTabsDoorWindowData() {
//    console.log('fetchTabsDoorWindowData, window');
//
//    var query = '';
//    query += "select value from device_frmpayload_data_status ";
//    query += "where device_name='Tabs-Door-Window' ";
//    query += "order by desc limit 3";
//    
//    var url = '';
//    url += 'http://144.76.67.158:8086/query?';
//    url += 'db=smart_city_to_go&pretty=true&';
//    url += 'q=' + encodeURIComponent(query);
//
//    const response = await fetch(url, {
//        method: 'GET',
//        credentials: 'same-origin',
//        redirect: 'follow',
//        agent: null,
//        headers: {
//            "Content-Type": "text/plain",
//            'Authorization': 'Basic ' + btoa('admin:admin'),
//        },
//        timeout: 5000
//    });
//    const json = await response.json();
//    var values = json.results[0].series[0].values;
////    console.log(values)
//
//    var timeText;
//    var textIn;
//    [1,2,3].forEach(i => {
////                    console.log(i);
//                    timeText = values[i-1][0].match(/\d\d:\d\d/);
//                    textIn = document.getElementsByClassName(`AictivityTextW-ActivitiyItem${i}`)[0];
//                    if (values[i-1][1] === 1) {
//                        // open
////                        console.log('open');
//                        textIn.textContent = timeText + ' Offen';
//                    } else {
//                        // closed
////                        console.log('closed');
//                        textIn.textContent = timeText + ' Geschlossen';
//                    }
//                    });
//
//    // last status
//    var windowImage = document.getElementsByClassName("WindowAndActivity-Window")[0];
//    var windowStatus = document.getElementsByClassName("WindowStatus-ActivityStatus")[0];
//    if (values[0][1] === 1) {
//        // open
////        console.log('open');
//        windowImage.src = WindowOpen;
//        windowStatus.textContent = 'Offen';
//        windowStatus.style.color = '#FFB266';
//    } else {
//        // closed
////        console.log('closed');
//        windowImage.src = WindowClosed;
//        windowStatus.textContent = 'Geschlossen'
//        windowStatus.style.color = '#00D97E';
//
//    }
//
//}

class WindowAndActivity extends React.Component {
    
//    componentDidMount() {
//        this.myInterval = setInterval(fetchTabsDoorWindowData, 10*1000);
//    };
//
//    componentWillUnmount() {
//        if (this.myTimeOut) {
//            clearInterval(this.myInterval);
//        }
//    }

    render(){
        return (
        <div className="WindowAndActivity">
        <AictivityTextW />
        </div>
        );
    }
};

//import WindowOpen from '../images/window open slice.png';
//import WindowClosed from '../images/window closed slice.png';
//<img src={WindowOpen} className="WindowAndActivity-Window" alt="" />

export default WindowAndActivity;


