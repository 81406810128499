import React from 'react';
import './WindowStatus.css';

import WindowAndActivity from './WindowAndActivity.js';
import WindowOpen from '../images/window open slice.png';
//import WindowClosed from '../images/window closed slice.png';
import locales from '../locales/locale.json';

function WindowStatus() {
  return (
          <div className="WindowStatus">
          <div className="WindowStatus-Heading" >{locales.window}</div>

          <img src={WindowOpen} className="WindowAndActivity-Window" alt="" />

          <div className="WindowStatus-ActivityStatus" > </div>
          <WindowAndActivity />
          </div>
  );
}

export default WindowStatus;


//import WindowOpen from '../images/window open slice.png';
//import WindowClosed from '../images/window closed slice.png';
//<img src={WindowOpen} className="WindowAndActivity-Window" alt="" />
