import React from 'react';
import './TimeAndDate.css';



function TimeAndDate() {
  return (
    <div className="TimeAndDate">
<div className="TimeAndDate-CurrentTime" > . </div>
<div className="TimeAndDate-DayNameAndDate" > . </div>
    </div>
  );
}

export default TimeAndDate;
